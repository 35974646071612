export const exposes = {
    activation: {
        SEBActivation: () => import('./SEBActivation/SEBActivation')
    }
}

export default function init() {
    return function tsgLoad() {
        return {
            navigation: []
        };
    };
};
